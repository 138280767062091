<template>
  <v-container fluid>
    <!-- PRECARGA  -->
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <h3 class="font-weight-light">Estas editando el las preguntas de la agresión <strong>{{ $route.params.id }} </strong> del area de <strong> {{currentPerfil}}</strong></h3>
    <v-row>
      <v-col class="text-right">
        <v-btn @click="dialog = true" elevation="0" color="primary">Agregar pregunta <v-icon>mdi-plus-thick</v-icon></v-btn>
      </v-col>
    </v-row>
    
    <!-- SI HAY PREGUNTAS -->
    <v-row v-if="preguntas.length">
      <v-col>
        <v-expansion-panels>
          <draggable v-model="preguntas" @end="newOrder" style="width: 100%" handle=".handle">
            <v-expansion-panel v-for="pregunta in preguntas" :key="pregunta.orden" class="">
              <v-expansion-panel-header ripple>
                <v-row no-gutters style="width: 100% !important">
                  <v-col class="d-flex">
                    <div class="mr-4 handle"><v-icon alt>mdi-swap-vertical-bold</v-icon></div>
                    <div style="width:100%">{{ pregunta.texto }}</div>
                    <div style="white-space: nowrap;" class=" justify-end">
                      <v-btn icon plain :ripple="false" id="btn-editar" @click="modalEdit(pregunta)"><v-icon>mdi-pencil</v-icon></v-btn>
                      <v-btn icon plain :ripple="false" id="btn-eliminar" @click="deletePregunta(pregunta.id)"><v-icon>mdi-delete-empty</v-icon></v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <v-list subheader dense>
                      <v-list-item v-for="respuesta in pregunta.respuestas" :key="respuesta.id" :class="{'green lighten-4': respuesta.is_true}">
                        <v-list-item-content >
                          <v-list-item-title v-text="respuesta.texto_respuesta"></v-list-item-title>
                          <v-list-item-subtitle v-show="respuesta.is_true">Esta suma 1 punto.</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-show="respuesta.is_true">
                          <v-icon color="grey lighten-1">mdi-check</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- SI NO HAY PREGUNTAS -->
    <v-row v-else align="center" justify="center" class="px-md-16 mx-md-12">
      <v-col cols="12">
        <v-card>
          <v-card-text class="text-center font-weight-bold">Aún no hay preguntas en esta agresión <strong>{{ currentId }}</strong>. <v-icon>mdi-emoticon-cry-outline</v-icon></v-card-text>
        </v-card>
      </v-col>
    </v-row>





    <!-- MODAL AGREGAR PREGUNTA -->
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="info">
          <v-btn icon dark @click="closeModal()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Agregar pregunta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModal()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="addPregunta()">
            <v-text-field filled dense flat  type="text" label="Agregar pregunta *" v-model="preguntaAdd.texto"></v-text-field>
            <v-text-field filled dense type="text" label="Sub Texto de la pregunta" v-model="preguntaAdd.subtexto"></v-text-field>
            <v-textarea label="Texto de mas info" prepend-inner-icon="mdi-information-outline" filled auto-grow rows="2" row-height="20"  v-model="preguntaAdd.help"></v-textarea>

            <div class="mb-6">
              <vue-editor v-model="preguntaAdd.textoInforme" placeholder="Texto que se presentara en el informe final PDF" />
            </div>

            <v-select :items='["lista", "checkbox", "radio-buttons"]' filled label="Tipo de pregunta *" hint="Lista: despliga, Checkbox: opciones, Radio-Button: una opcion.." v-model="preguntaAdd.tipo"></v-select>
            <v-divider></v-divider>

            <!-- RESPUESTAS DINAMICAMENTE -->
            <v-card v-for="(respuesta, index) in preguntaAdd.respuestas" :key="respuesta.id" class="px-3 mb-6" color="#E8EAF6">
              <v-row>
                <v-col cols="8">
                  <v-text-field filled dense type="text" :label="'Texto de la respuesta #' + index" v-model="preguntaAdd.respuestas[index].texto_respuesta"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-switch dense color="success" v-model="preguntaAdd.respuestas[index].is_true" hide-details class="shrink" label="Suma 1 punto."></v-switch>
                </v-col>
                <v-col cols="">
                  <v-btn icon @click="rmRespuesta(index)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <vue-editor placeholder="Tooltip de esta respuesta" v-model="preguntaAdd.respuestas[index].tooltip"/>
                </v-col>
                <v-col cols="7">
                  <vue-editor placeholder="Texto que se vera en el reporte final" v-model="preguntaAdd.respuestas[index].texto_reporte"/>
                </v-col>
              </v-row>
            </v-card>
            
            

            <v-subheader>
              <div class="text-center">
                <v-btn small plain elevation="0" tile color="success" @click="addRespuesta()">
                  <v-icon left>mdi-pencil</v-icon>
                  Agregar una posible respuesta
                </v-btn>
              </div>
            </v-subheader>

            <v-divider></v-divider>
            <v-divider></v-divider>

            <div class="mt-9 text-center"><v-btn block depressed color="primary" type="submit">Agregar esta pregunta <v-icon>mdi-cloud-question</v-icon></v-btn></div>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>


    <!-- MODAL EDITAR PREGUNTA -->
    <v-dialog v-model="dialogEdit" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="info">
          <v-btn icon dark @click="closeModalEdit()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Editar pregunta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModalEdit()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="editPregunta()">
            <v-text-field filled dense flat  type="text" label="Agregar pregunta *" v-model="currentEdito.texto"></v-text-field>
            <v-text-field filled dense type="text" label="Sub Texto de la pregunta" v-model="currentEdito.subtexto"></v-text-field>
            <v-textarea label="Texto de mas info" prepend-inner-icon="mdi-information-outline" filled auto-grow rows="2" row-height="20"  v-model="currentEdito.help"></v-textarea>

            <div class="mb-6">
              <vue-editor v-model="currentEdito.textoInforme" placeholder="Texto que se presentara en el informe final PDF" />
            </div>

            <v-select :items='["lista", "checkbox", "radio-buttons"]' filled label="Tipo de pregunta *" hint="Lista: despliga, Checkbox: varias opciones, Radio-Button: una opcion." v-model="currentEdito.tipo"></v-select>
            <v-divider></v-divider>

            <!-- RESPUESTAS DINAMICAMENTE -->
            <v-card v-for="(respuesta, index) in currentEdito.respuestas" :key="respuesta.id" class="px-3 mb-6" color="#E8EAF6">
              <v-row>
                <v-col cols="8">
                  <v-text-field filled dense type="text" :label="'Texto de la respuesta #' + index" v-model="currentEdito.respuestas[index].texto_respuesta"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-switch dense color="success" v-model="currentEdito.respuestas[index].is_true" hide-details class="shrink" label="Suma 1 punto."></v-switch>
                </v-col>
                <v-col cols="">
                  <v-btn icon @click="rmEditRespuesta(index)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <vue-editor placeholder="Tooltip de esta respuesta" v-model="currentEdito.respuestas[index].tooltip"/>
                </v-col>
                <v-col cols="7">
                  <vue-editor placeholder="Texto que se vera en el reporte final" v-model="currentEdito.respuestas[index].texto_reporte"/>
                </v-col>
              </v-row>
            </v-card>
            
            

            <v-subheader>
              <v-btn small block x-small plain elevation="0" tile color="success" @click="addEditRespuesta()">
                <v-icon left>mdi-pencil</v-icon>
                Agregar una posible respuesta
              </v-btn>
            </v-subheader>

            <v-divider></v-divider>
            <v-divider></v-divider>

            <v-btn block elevation="0" class="mt-12 editbtn" type="submit">Guardar los cambios</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
import {db} from '../../main'
import draggable from 'vuedraggable'

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import '@ckeditor/ckeditor5-build-classic/build/translations/es'


export default {
  name: 'AgresionTest',
  data() {
    return {
      currentPerfil: this.$route.params.category,
      currentModulo: this.$route.params.section,
      currentId: this.$route.params.id,

       

      isLoading: true,
      dialog: false,
      dialogEdit: false,
      content: "",

      // PA EDITAR UNA PREGUNTA
      currentEdito: [],
      // _templateArray: [{ name: "TEST" }],

      // Mis preguntas obtenidas desde firebase
      preguntas: [],

      // AGREGAR PREGUNTA
      preguntaAdd: {
        texto: "",
        subtexto: "",
        help: "",
        textoInforme: "",
        tipo: "",
        orden: "",
        respuestas: []
      },
    }

  },
  components: {
    draggable
  },
  created() {
    this.getData()
  },
  methods: {
    // OBTENGO DATOS DE PREGUNTA
    async getData() {
      this.isLoading = true
      try {
        const snapshot = await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(this.currentId).collection('preguntas').orderBy('orden').get();
        // const snapshot = await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).orderBy('orden').get();
        const preguntas = []
        snapshot.forEach(doc => {
          let preguntaData = doc.data()
          preguntaData.id = doc.id
          preguntas.push(preguntaData)
        })
        this.preguntas = preguntas
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },

    // NUEVO ORDEN DE PREGUNTAS
    async newOrder() {
      // Get a new write batch
      const batch = db.batch();
      // Prepara batch para nuevo orden 
      this.preguntas.forEach((pregunta, index) => {
        const Ref = db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(this.currentId).collection('preguntas').doc(pregunta.id);
        batch.update(Ref, {orden: index});
      })
      try {
        // Commit the batch
        await batch.commit();
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },

    // GUARDO DATOS DE PREGUNTA
    async addPregunta() {
      try {
        if(this.preguntaAdd.texto && this.preguntaAdd.tipo) {
          this.preguntaAdd.orden = this.preguntas.length
          await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(this.currentId).collection('preguntas').add(this.preguntaAdd).then(() => {
            this.getData()
            this.closeModal()
          })
        } else {
          alert('Algunos campos obligatorios faltan obligatorios')
        }
      } catch (error) {
        console.log(error)
      }
    },

    // EDITO LA PREGUNTA
    async editPregunta() {
      const docuid = this.currentEdito.id
      delete this.currentEdito.id
      try {
        // const snapshot = await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(this.currentId).collection('preguntas').orderBy('orden').get();
        await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(this.currentId).collection('preguntas').doc(docuid).update(this.currentEdito)
        this.closeModalEdit()
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },

    // ELIMINO DE PREGUNTA
    async deletePregunta(preguntaID) {
      let proceed = confirm("Estas segura(o) que quieres eliminar esta pregunta ID: " + preguntaID);
      if (proceed) {
        await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(this.currentId).collection('preguntas').doc(preguntaID).delete().then(() => {
          // console.log('La pregunta SE elimino ID: ' + preguntaID)
        }).catch((error) => {
          console.error("Error removing document: ", error);
        });
        this.getData()
      } else {
        console.log('La pregunta no se elimino ID: ' + preguntaID)
      }
    },

    // FUNCIONES DE FORM AGREGAR PREGUNTA
      closeModal() {
        this.preguntaAdd = {
          texto: "",
          subtexto: "",
          help: "",
          textoInforme: "",
          tipo: "",
          orden: "",
          respuestas: []
        }
        this.dialog = false
      },
      addRespuesta() {
        const resp = {
          texto_respuesta: '',
          texto_reporte: '',
          is_true: false
        }
        this.preguntaAdd.respuestas.push(resp)
        // console.log(this.preguntaAdd.respuestas)
      },
      rmRespuesta(i) {
        this.preguntaAdd.respuestas.splice(i, 1);
        // console.log(this.preguntaAdd.respuestas)
      },
      

    //FUNCIONES DE FORM EDITAR PREGUNTA\
      modalEdit(datos) {
        this.currentEdito = JSON.parse(JSON.stringify(datos))
        // this.currentEdito = Object.assign({}, datos);
        this.dialogEdit = true
      },
      addEditRespuesta() {
        const resp = {
          texto_respuesta: '',
          texto_reporte: '',
          is_true: false
        }
        this.currentEdito.respuestas.push(resp)
        // console.log(this.preguntaAdd.respuestas)
      },
      rmEditRespuesta(i) {
        this.currentEdito.respuestas.splice(i, 1);
        // console.log(this.preguntaAdd.respuestas)
      },
      closeModalEdit() {
        this.currentEdito = []
        this.dialogEdit = false
      },

    ...mapMutations(['currentChange'])
  },
  computed: {
    ...mapState(['usuario', 'currentComponente'])
  }
}
</script>

<style scoped>
#btn-editar i:hover, #btn-eliminar i:hover {
  transform: scale(1.15);
}
#btn-editar {
  color: #6A1B9A;
}
#btn-eliminar {
  color: #F44336;
}
.editbtn:hover {
  background-color: #F3E5F5;
}
</style>