<template>
  <div>
    <v-container> 
      <div class="text-center mb-3">
        <h3 class="font-weight-light pb-4">Estas editando el modulo<strong> {{ currentModulo }} de {{currentPerfil}}</strong></h3>
      </div>

      <!-- PRECARGA  -->
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>


    <v-row no-gutters dense>
      <v-col class="text-right">
        <v-btn @click="dialogEditContent = true" elevation="0" class="mr-5" small>Editar contenido</v-btn>
        <v-btn @click="dialog = true" elevation="0" color="primary">Agregar pregunta <v-icon>mdi-plus-thick</v-icon></v-btn>
      </v-col>
    </v-row>
      <!-- <v-container v-show="!isLoading" class="grid-list-md"> -->
      <v-container class="grid-list-md">
        <!-- <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-btn @click="dialog = true">Agregar pregunta <v-icon>mdi-plus-thick</v-icon></v-btn>
          </v-col> -->
           <!-- <v-col cols="6" class="text-center"> -->
            <!-- <v-btn @click="newOrder">Guardar nuevo orden <v-icon>mdi-plus-thick</v-icon></v-btn> -->
          <!-- </v-col> -->
        <!-- </v-row> -->

        <!-- SI HAY PREGUNTAS -->
        <v-container v-if="preguntas.length">
          <v-expansion-panels>
            <draggable v-model="preguntas"  @end="newOrder">
              <v-expansion-panel v-for="pregunta in preguntas" :key="pregunta.orden" class="">
                <v-expansion-panel-header ripple>
                  <v-row no-gutters style="width: 100%">
                    <v-col class="d-flex">
                      <div class="mr-4"><v-icon alt>mdi-swap-vertical-bold</v-icon></div>
                      <div style="width:100%">{{ pregunta.texto }}</div>
                      <div style="white-space: nowrap;" class=" justify-end">
                        <v-btn icon plain :ripple="false" id="btn-editar" @click="modalEdit(pregunta)"><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn icon plain :ripple="false" id="btn-eliminar" @click="deletePregunta(pregunta.id)"><v-icon>mdi-delete-empty</v-icon></v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-list subheader dense>
                        <v-list-item v-for="respuesta in pregunta.respuestas" :key="respuesta.id" :class="{'green lighten-4': respuesta.is_true}">
                          <v-list-item-content >
                            <v-list-item-title v-text="respuesta.texto_respuesta"></v-list-item-title>
                            <v-list-item-subtitle v-show="respuesta.is_true">Esta es la respuesta correcta</v-list-item-subtitle>
                          </v-list-item-content>
    
                          <v-list-item-action v-show="respuesta.is_true">
                            <v-icon color="grey lighten-1">mdi-check</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </draggable>
          </v-expansion-panels>
        </v-container>



        <!-- <v-row v-if="preguntas.length" align="center" justify="center" class="px-md-16 mx-md-12">
          <v-col cols="12" v-for="pregunta in preguntas" :key="pregunta.id" class="">
            <v-card dense elevation="2">
              <v-card-title id="actions">
                {{ pregunta.texto }}
                <v-spacer></v-spacer>
                <v-btn icon plain :ripple="false" id="btn-editar" @click="modalEdit(pregunta)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon plain :ripple="false" id="btn-eliminar" @click="deletePregunta(pregunta.id)"><v-icon>mdi-delete-empty</v-icon></v-btn>
              </v-card-title>
              <v-card-subtitle>{{ pregunta.subtexto }}</v-card-subtitle>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-list subheader dense>
                      <v-list-item v-for="respuesta in pregunta.respuestas" :key="respuesta.id" :class="{'green lighten-4': respuesta.is_true}">
                        <v-list-item-content >
                          <v-list-item-title v-text="respuesta.texto_respuesta"></v-list-item-title>
                          <v-list-item-subtitle v-show="respuesta.is_true">Esta es la respuesta correcta</v-list-item-subtitle>
                        </v-list-item-content>
  
                        <v-list-item-action v-show="respuesta.is_true">
                          <v-icon color="grey lighten-1">mdi-check</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->



        <!-- SI NO HAY PREGUNTAS -->
        <v-row v-else align="center" justify="center" class="px-md-16 mx-md-12">
          <v-col cols="12">
            <v-card>
              <v-card-text class="text-center font-weight-bold">Aún no hay preguntas en el modulo <strong>{{ currentModulo }}</strong> para <strong>{{ currentPerfil}}</strong>. <v-icon>mdi-emoticon-cry-outline</v-icon></v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </v-container>


    <!-- MODAL AGREGAR PREGUNTA -->
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="info">
          <v-btn icon dark @click="closeModal()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Agregar pregunta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModal()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="addPregunta()">
            <v-text-field filled dense flat  type="text" label="Agregar pregunta *" v-model="preguntaAdd.texto"></v-text-field>
            <v-text-field filled dense type="text" label="Sub Texto de la pregunta" v-model="preguntaAdd.subtexto"></v-text-field>
            <v-textarea label="Texto de mas info" prepend-inner-icon="mdi-information-outline" filled auto-grow rows="2" row-height="20"  v-model="preguntaAdd.help"></v-textarea>

            <div class="mb-6">
              <vue-editor v-model="preguntaAdd.textoInforme" placeholder="Texto que se presentara en el informe final PDF" />
            </div>

            <v-select :items='["lista", "checkbox", "radio-buttons"]' filled label="Tipo de pregunta *" hint="Lista: despliga, Checkbox: opciones, Radio-Button: una opcion.." v-model="preguntaAdd.tipo"></v-select>
            <v-divider></v-divider>

            <!-- RESPUESTAS DINAMICAMENTE -->
            <v-card v-for="(respuesta, index) in preguntaAdd.respuestas" :key="respuesta.id" class="px-3 mb-6" color="#E8EAF6">
              <v-row>
                <v-col cols="8">
                  <v-text-field filled dense type="text" :label="'Texto de la respuesta #' + index" v-model="preguntaAdd.respuestas[index].texto_respuesta"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-switch dense color="success" v-model="preguntaAdd.respuestas[index].is_true" hide-details class="shrink" label="Respuesta correcta."></v-switch>
                </v-col>
                <v-col cols="">
                  <v-btn icon @click="rmRespuesta(index)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <vue-editor placeholder="Tooltip de esta respuesta" v-model="preguntaAdd.respuestas[index].tooltip"/>
                </v-col>
                <v-col cols="7">
                  <vue-editor placeholder="Texto que se vera en el reporte final" v-model="preguntaAdd.respuestas[index].texto_reporte"/>
                </v-col>
              </v-row>
            </v-card>
            
            

            <v-subheader>
              <div class="text-center">
                <v-btn small plain elevation="0" tile color="success" @click="addRespuesta()">
                  <v-icon left>mdi-pencil</v-icon>
                  Agregar una posible respuesta
                </v-btn>
              </div>
            </v-subheader>

            <v-divider></v-divider>
            <v-divider></v-divider>

            <div class="mt-9 text-center"><v-btn block depressed color="primary" type="submit">Agregar esta pregunta <v-icon>mdi-cloud-question</v-icon></v-btn></div>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>


    <!-- MODAL EDITAR PREGUNTA -->
    <v-dialog v-model="dialogEdit" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="info">
          <v-btn icon dark @click="closeModalEdit()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Editar pregunta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModalEdit()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="editPregunta()">
            <v-text-field filled dense flat  type="text" label="Agregar pregunta *" v-model="currentEdito.texto"></v-text-field>
            <v-text-field filled dense type="text" label="Sub Texto de la pregunta" v-model="currentEdito.subtexto"></v-text-field>
            <v-textarea label="Texto de mas info" prepend-inner-icon="mdi-information-outline" filled auto-grow rows="2" row-height="20"  v-model="currentEdito.help"></v-textarea>

            <div class="mb-6">
              <vue-editor v-model="currentEdito.textoInforme" placeholder="Texto que se presentara en el informe final PDF" />
            </div>

            <v-select :items='["lista", "checkbox", "radio-buttons"]' filled label="Tipo de pregunta *" hint="Lista: despliga, Checkbox: varias opciones, Radio-Button: una opcion." v-model="currentEdito.tipo"></v-select>
            <v-divider></v-divider>

            <!-- RESPUESTAS DINAMICAMENTE -->
            <v-card v-for="(respuesta, index) in currentEdito.respuestas" :key="respuesta.id" class="px-3 mb-6" color="#E8EAF6">
              <v-row>
                <v-col cols="8">
                  <v-text-field filled dense type="text" :label="'Texto de la respuesta #' + index" v-model="currentEdito.respuestas[index].texto_respuesta"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-switch dense color="success" v-model="currentEdito.respuestas[index].is_true" hide-details class="shrink" label="Respuesta correcta."></v-switch>
                </v-col>
                <v-col cols="">
                  <v-btn icon @click="rmEditRespuesta(index)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <vue-editor placeholder="Tooltip de esta respuesta" v-model="currentEdito.respuestas[index].tooltip"/>
                </v-col>
                <v-col cols="7">
                  <vue-editor placeholder="Texto que se vera en el reporte final" v-model="currentEdito.respuestas[index].texto_reporte"/>
                </v-col>
              </v-row>
            </v-card>
            
            

            <v-subheader>
              <v-btn small block x-small plain elevation="0" tile color="success" @click="addEditRespuesta()">
                <v-icon left>mdi-pencil</v-icon>
                Agregar una posible respuesta
              </v-btn>
            </v-subheader>

            <v-divider></v-divider>
            <v-divider></v-divider>

            <v-btn block elevation="0" class="mt-12 editbtn" type="submit">Guardar los cambios</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>






    <!-- MODAL editar Contenido seccion  -->
    <v-dialog v-model="dialogEditContent" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="blue-grey">
          <v-btn icon dark @click="closeModalEditContent()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Editar descripción de la sección {{ currentModulo }} de {{currentPerfil}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModalEditContent()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="editContent()">

            <!-- <v-text-field filled dense flat  type="text" label="Nombre de la agresión *" v-model="currentEditoContent.texto"></v-text-field> -->
            <small class="text--secondary">Primer texto <span class="text--secondary"></span></small>
            <ckeditor :editor="editor" v-model="currentEditoContent.primero" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <small class="text--secondary">Segundo texto</small>
            <!-- <small class="text--secondary">Texto informativo <v-icon small>mdi-school</v-icon> </small> -->
            <ckeditor :editor="editor" v-model="currentEditoContent.segundo" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <v-divider></v-divider>
            <small class="text--secondary">Tercer texto, <span class="text--secondary">Este no se ve, pero agrego uno extra por si se llegara a necesitar XD</span></small>
            <ckeditor :editor="editor" v-model="currentEditoContent.tercero" :config="editorConfig"></ckeditor>

            <v-btn block elevation="0" class="mt-12 editbtn" type="submit">Guardar los cambios</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
import {db} from '../../main'
import draggable from 'vuedraggable'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'


export default {
  name: 'RegularTest',
  data() {
    return {
      currentPerfil: this.$route.params.category,
      currentModulo: this.$route.params.section,
      isLoading: true,
      dialog: false,
      content: "",
      dialogEditContent: false,
      currentEditoContent: {},

      // PA EDITAR UNA PREGUNTA
      dialogEdit: false,
      currentEdito: [],
      // _templateArray: [{ name: "TEST" }],

      // Mis preguntas obtenidas desde firebase
      preguntas: [],

      // AGREGAR PREGUNTA
      preguntaAdd: {
        texto: "",
        subtexto: "",
        help: "",
        textoInforme: "",
        tipo: "",
        orden: "",
        respuestas: []
      },

      editor: ClassicEditor,
      editorConfig: {
        language: 'es',
        toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'link', 'numberedList', 'bulletedList', '|', 'insertTable', 'blockQuote', '|', 'mediaEmbed', '|', 'undo', 'redo' ],
      },
    }

  },
  components: {
    draggable
  },
  created() {
    this.getData()
  },
  methods: {
    // OBTENGO DATOS DE PREGUNTA
    async getData() {
      this.isLoading = true
      try {
        const snapshot = await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).orderBy('orden').get();
        const preguntas = []
        snapshot.forEach(doc => {
          let preguntaData = doc.data()
          preguntaData.id = doc.id
          preguntas.push(preguntaData)
        })
        // ordeno el array por su orden asignado en valor orden
        // preguntas.sort((a, b) => a.orden - b.orden );
        this.preguntas = preguntas
        
        
        //Obtengo datos del contenido - codigo spaguetti U_U
        const doc = await db.collection('contenidos').doc(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).get()
        this.currentEditoContent = doc.data()
        // console.log(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase());

        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },
    // NUEVO ORDEN DE PREGUNTAS
    async newOrder() {
      // Get a new write batch
      const batch = db.batch();
      // Prepara batch para nuevo orden 
      this.preguntas.forEach((pregunta, index) => {
        const Ref = db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(pregunta.id);
        batch.update(Ref, {orden: index});
        console.log('anterior: ' + pregunta.orden + ' | nuevo: ' + index)
      })
      try {
        // Commit the batch
        await batch.commit();
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },
    // GUARDO DATOS DE PREGUNTA
    async addPregunta() {
      try {
        // if(this.preguntaAdd.texto, this.preguntaAdd.orden, this.preguntaAdd.respuestas) {
        if(this.preguntaAdd.texto && this.preguntaAdd.tipo) {
          this.preguntaAdd.orden = this.preguntas.length
          await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).add(this.preguntaAdd).then(() => {
            this.getData()
            this.closeModal()
          })
        } else {
          alert('Algunos campos obligatorios faltan obligatorios')
        }
      } catch (error) {
        console.log(error)
      }
    },
    // EDITO LA PREGUNTA
    async editPregunta() {
      const docuid = this.currentEdito.id
      delete this.currentEdito.id
      try {
        await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(docuid).update(this.currentEdito)
        this.closeModalEdit()
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },
    // ELIMINO DE PREGUNTA
    async deletePregunta(preguntaID) {
      let proceed = confirm("Estas segura(o) que quieres eliminar esta pregunta ID: " + preguntaID);
      if (proceed) {
        await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(preguntaID).delete().then(() => {
          // console.log('La pregunta SE elimino ID: ' + preguntaID)
        }).catch((error) => {
          console.error("Error removing document: ", error);
        });
        this.getData()
      } else {
        console.log('La pregunta no se elimino ID: ' + preguntaID)
      }
    },

    // FUNCIONES DE FORM AGREGAR PREGUNTA
      closeModal() {
        this.preguntaAdd = {
          texto: "",
          subtexto: "",
          help: "",
          textoInforme: "",
          tipo: "",
          orden: "",
          respuestas: []
        }
        this.dialog = false
      },
      addRespuesta() {
        const resp = {
          texto_respuesta: '',
          texto_reporte: '',
          is_true: false
        }
        this.preguntaAdd.respuestas.push(resp)
        // console.log(this.preguntaAdd.respuestas)
      },
      rmRespuesta(i) {
        this.preguntaAdd.respuestas.splice(i, 1);
        // console.log(this.preguntaAdd.respuestas)
      },

    //FUNCIONES DE FORM EDITAR PREGUNTA\
      modalEdit(datos) {
        this.currentEdito = JSON.parse(JSON.stringify(datos))
        // this.currentEdito = Object.assign({}, datos);
        this.dialogEdit = true
      },
      addEditRespuesta() {
        const resp = {
          texto_respuesta: '',
          texto_reporte: '',
          is_true: false
        }
        this.currentEdito.respuestas.push(resp)
        // console.log(this.preguntaAdd.respuestas)
      },
      rmEditRespuesta(i) {
        this.currentEdito.respuestas.splice(i, 1);
        // console.log(this.preguntaAdd.respuestas)
      },
      closeModalEdit() {
        this.currentEdito = []
        this.dialogEdit = false
      },











      // EDITO Contenido seccion
    async editContent() {
      const docuid = this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()
      


      // console.log(this.agresions)
      // delete this.currentEditoContent.id
      try {
        await db.collection('contenidos').doc(docuid).update(this.currentEditoContent)
        this.closeModalEditContent()
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },
    // modalEditContent(datos) {
    //   this.currentEditoContent = JSON.parse(JSON.stringify(datos))
    //   this.dialogEditContent = true
    // },
    async closeModalEditContent() {
      try {
        const doc = await db.collection('contenidos').doc(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).get()
        this.currentEditoContent = doc.data()
        this.dialogEditContent = false
      } catch (error) {
        console.log(error)
      }
    },

    ...mapMutations(['currentChange'])
  },
  computed: {
    ...mapState(['usuario', 'currentComponente'])
  }
}
</script>

<style scoped>
#btn-editar i:hover, #btn-eliminar i:hover {
  transform: scale(1.15);
}
#btn-editar {
  color: #6A1B9A;
}
#btn-eliminar {
  color: #F44336;
}
.editbtn:hover {
  background-color: #F3E5F5;
}
</style>