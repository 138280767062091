<template>
  <v-container fluid>
    <h1>Diagnostico</h1>
    <v-breadcrumbs exact: true class="ma-0 pa-0 text-capitalize" color="success">
      <v-breadcrumbs-item :to="$route.params.category">{{ $route.params.category }}</v-breadcrumbs-item>
      <v-breadcrumbs-item :to="$route.params.section">{{ $route.params.section }}</v-breadcrumbs-item>
      <v-breadcrumbs-item :to="$route.params.id">{{ $route.params.id }}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <!-- SI NO HAY COMPONENTE -->
    <div v-if="!this.$route.params.section && !this.$route.params.id">
      <div class="text-center">
        <v-row no-gutters dense>
      <v-col class="text-right">
        <v-btn @click="dialogEditContent = true, getData($route.params.category)" elevation="0" class="mr-5" small>Editar contenido</v-btn>
      </v-col>
    </v-row>
        <h2 class="font-weight-medium pb-2">Porfa selecciona con que Modulo deseas trabajar: </h2>
      </div>
      <v-row align="center" justify="center" class="px-md-16 mx-md-12">
        <v-col cols=12 md=4 sm=1 v-for="modulo in modulos" :key="modulo.title">
          <v-card outlined hover class="text-center pt-16 mycard transition-swing" :to="{name: 'DiagnosticoSec', params: {category: $route.params.category, section:modulo.modulo, testView: modulo.testView} }">
            <!-- <v-card outlined hover class="text-center pt-16 mycard transition-swing" replace :to="{name: 'DiagnosticoSec', params: {category: $route.params.category, section:modulo.modulo, testView: modulo.testView} }"> -->
            <v-icon x-large class="" :style="{color: modulo.color}">{{modulo.icon}}</v-icon>
            <h3 class="mt-8 pb-4" :style="{color: modulo.color}">{{modulo.titulo}}</h3>
          </v-card>
        </v-col>
      </v-row>
    
    
    
    <!-- MODAL editar Contenido seccion  -->
    <v-dialog v-model="dialogEditContent" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="blue-grey">
          <v-btn icon dark @click="dialogEditContent = false"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Editar descripción de {{ $route.params.category }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="dialogEditContent = false" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="editContent($route.params.category)">

            <!-- <v-text-field filled dense flat  type="text" label="Nombre de la agresión *" v-model="currentEditoContent.texto"></v-text-field> -->
            <small class="text--secondary">Primer texto <span class="text--secondary"></span></small>
            <ckeditor :editor="editor" v-model="currentEditoContent.primero" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <small class="text--secondary">Segundo texto, <span class="text--secondary">Este no se ve, pero agrego uno extra por si se llegara a necesitar XD</span></small>
            <!-- <small class="text--secondary">Texto informativo <v-icon small>mdi-school</v-icon> </small> -->
            <ckeditor :editor="editor" v-model="currentEditoContent.segundo" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <v-divider></v-divider>
            <small class="text--secondary">Tercer texto, <span class="text--secondary">Este no se ve, pero agrego uno extra por si se llegara a necesitar XD</span></small>
            <ckeditor :editor="editor" v-model="currentEditoContent.tercero" :config="editorConfig"></ckeditor>

            <v-btn block elevation="0" class="mt-12 editbtn" type="submit">Guardar los cambios</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>



    </div>

    
    <!-- CARGO COMPONENTE DINAMICAMENTE -->
    <div v-else-if="this.$route.params.section && !this.$route.params.id" class="mt-4">
      <!-- <keep-alive> -->
        <component :is="mycomp[$route.params.section]"/>
      <!-- </keep-alive> -->
    </div>

    <!-- CARGO COMPONENTE test agresiones -->
    <div v-else class="mt-4">
      <!-- <keep-alive> -->
        <component :is="'AgresionTest'"/>
      <!-- </keep-alive> -->
    </div>




  </v-container>
</template>

<script>
import RegularTest from '@/components/diagnostico/RegularTest'
import AgresionList from '@/components/diagnostico/AgresionList'
import AgresionTest from '@/components/diagnostico/AgresionTest'
import {db} from '../main'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'

export default {
  name: 'Diagnostico',
  data: () => ({
    currentPerfil: '',


    currentComp: '',

    dialogEditContent: false,
    currentEditoContent: {},

    editor: ClassicEditor,
      editorConfig: {
        language: 'es',
        toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'link', 'numberedList', 'bulletedList', '|', 'insertTable', 'blockQuote', '|', 'mediaEmbed', '|', 'undo', 'redo' ],
      },


    mycomp: {
      perfil: 'RegularTest',
      habitos: 'RegularTest',
      agravantes: 'RegularTest',
      agresiones: 'AgresionList',
    },
    modulos: [
        {
          orden: 0,
          color: '#7C4DFF',
          icon: 'mdi-alien',
          modulo: 'perfil',
          testView: 'RegularTest',
          titulo: 'Perfil'
        },
         {
          orden: 1,
          color: '#33691E',
          icon: 'mdi-alien',
          modulo: 'habitos',
          testView: 'RegularTest',
          titulo: 'Hábitos'
        },
         {
          orden: 2,
          color: '#BF360C',
          icon: 'mdi-alien',
          modulo: 'agravantes',
          testView: 'RegularTest',
          titulo: 'Agravantes'
        },
         {
          orden: 3,
          color: '#37474F',
          icon: 'mdi-alien',
          modulo: 'agresiones',
          testView: 'AgresionList',
          titulo: 'Agresiones'
        }
      ],
  }),

  // created() {
  //   this.getData()
  // },
  methods: {
    // OBTENGO DATOS DE PREGUNTA
    async getData(categ) {
      try {
        //Obtengo datos del contenido - codigo spaguetti U_U
        const docuid = categ.toLowerCase()
        const doc = await db.collection('contenidos').doc(docuid).get()
        this.currentEditoContent = doc.data()
        // console.log(categ.toLowerCase());

      } catch (error) {
        console.log(error)
      }
    },    


    // EDITO Contenido seccion
    async editContent(ca) {
      const docuid = ca.toLowerCase()
      try {
        await db.collection('contenidos').doc(docuid).update(this.currentEditoContent)
        this.dialogEditContent = false
        // this.getData()
      } catch (error) {
        console.log(error)
      }
    },

  },

  components: {
    RegularTest, AgresionList, AgresionTest
  },
  // watch: {
  //   // '$route' () {
  //   //   if (this.$route.params.section && !this.$route.params.id) {
  //   //     this.currentComp = this.mycomp[this.$route.params.section]
  //   //   }
  //   //   else if (this.$route.params.section && this.$route.params.id) {
  //   //     this.currentComp = "AgresionTest"
  //   //   } else {
  //   //     this.currentComp = ''
  //   //   }
  //   // },
  //   // eslint-disable-next-line no-unused-vars

  // },

}
</script>