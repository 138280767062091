<template>
  <v-container fluid>
    <!-- PRECARGA  -->
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <h3 class="font-weight-light">Estas editando el modulo<strong> {{ currentModulo }} de {{currentPerfil}}</strong></h3>

    <v-row no-gutters dense>
      <v-col class="text-right">
        <v-btn @click="dialogEditContent = true" elevation="0" class="mr-5" small>Editar contenido</v-btn>
        <v-btn @click="dialog = true" elevation="0" color="primary">Agregar agresión <v-icon>mdi-plus-thick</v-icon></v-btn>
      </v-col>
    </v-row>

    <!-- SI HAY Agresiones -->
    <v-container v-if="agresions.length">
      <draggable v-model="agresions" @end="newOrder" handle=".handle">
        <transition-group tag="div" class="row justify-top" name="grid" type="transition">
          <v-col cols=12 md=4 sm=1 v-for="agresion in agresions" :key="agresion.orden">
            <v-card :style="'border-color:' + agresion.color" class="card-agresion" elevation="2">
              <v-card-title><span class="text-truncate">{{ agresion.texto }}</span></v-card-title>
              <v-card-text v-html="agresion.subtexto" style="overflow-y: auto; height:100px"></v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn plain color="primary" small 
                  :to="{name: 'DiagnosticoSecAgre', params: {category: $route.params.category, section: $route.params.section, id: agresion.id} }"
                ><v-icon>mdi-table-of-contents</v-icon> Editar contenido</v-btn>
                <v-spacer></v-spacer>
                <v-btn icon x-small id="btn-edit" plain color="indigo" @click="modalEdit(agresion)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon x-small id="btn-delete" plain color="error" @click="deleteAgresion(agresion.id)"><v-icon>mdi-delete</v-icon></v-btn>
                <v-btn icon x-small id="btn-move" plain color="blue-grey" class="handle"><v-icon>mdi-cursor-move</v-icon></v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </transition-group>
      </draggable>
    </v-container>

    <!-- SI NO HAY Agresiones -->
    <v-row v-else align="center" justify="center" class="px-md-16 mx-md-12">
      <v-col cols="12">
        <v-card>
          <v-card-text class="text-center font-weight-bold">Aún no hay agresiones en <strong>{{ currentPerfil }}</strong>. <v-icon>mdi-emoticon-cry-outline</v-icon></v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- MODAL AGREGAR Agresion -->
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="info">
          <v-btn icon dark @click="closeModal()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Agregar agresión</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModal()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="addAgresion()">
            <v-text-field filled dense flat  type="text" label="Nombre de la agresión *" v-model="agresionAdd.texto"></v-text-field>
            <small class="text--secondary">Resumen de la agresión: <span class="text--secondary">Lo que se muestra a la hora de seleccionar las agresiones</span></small>
            <ckeditor :editor="editor" v-model="agresionAdd.subtexto" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <small class="text--secondary">Texto de mas info: Información extra a la hora de seleccionar las agresiones</small>
            <ckeditor :editor="editor" v-model="agresionAdd.help" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <v-divider></v-divider>
            <small class="text--secondary">Texto que se presentara en el informe final PDF</small>
            <ckeditor :editor="editor" v-model="agresionAdd.textoInforme" :config="editorConfig"></ckeditor>

            <label for="color">Selecciona un color para la agresión</label>
            <input type="color" id="head" name="color" v-model="agresionAdd.color">

            <div class="mt-9 text-center"><v-btn block depressed color="primary" type="submit">Agregar esta agresión <v-icon>mdi-cloud-question</v-icon></v-btn></div>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- MODAL EDITAR Agresion -->
    <v-dialog v-model="dialogEdit" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="info">
          <v-btn icon dark @click="closeModalEdit()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Editar agresión</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModalEdit()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="editAgresion()">

            <v-text-field filled dense flat  type="text" label="Nombre de la agresión *" v-model="currentEdito.texto"></v-text-field>
            <small class="text--secondary">Resumen de la agresión: <span class="text--secondary">Lo que se muestra a la hora de seleccionar las agresiones</span></small>
            <ckeditor :editor="editor" v-model="currentEdito.subtexto" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <small class="text--secondary">Texto de mas info: Información extra a la hora de seleccionar las agresiones</small>
            <ckeditor :editor="editor" v-model="currentEdito.help" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <v-divider></v-divider>
            <small class="text--secondary">Texto que se presentara en el informe final PDF</small>
            <ckeditor :editor="editor" v-model="currentEdito.textoInforme" :config="editorConfig"></ckeditor>

            <label for="color">Selecciona un color para la agresión</label>
            <input type="color" id="head" name="color" v-model="currentEdito.color">





            <!-- <v-text-field filled dense flat  type="text" label="Agregar pregunta *" v-model="currentEdito.texto"></v-text-field>
            <v-text-field filled dense type="text" label="Sub Texto de la pregunta" v-model="currentEdito.subtexto"></v-text-field>
            <v-textarea label="Texto de mas info" prepend-inner-icon="mdi-information-outline" filled auto-grow rows="2" row-height="20"  v-model="currentEdito.help"></v-textarea>

            <div class="mb-6">
              <vue-editor v-model="currentEdito.textoInforme" placeholder="Texto que se presentara en el informe final PDF" />
            </div>

            <v-select :items='["lista", "checkbox", "radio-buttons"]' filled label="Tipo de pregunta *" hint="Lista: despliga, Checkbox: varias opciones, Radio-Button: una opcion." v-model="currentEdito.tipo"></v-select>
            <v-divider></v-divider> -->

            <v-btn block elevation="0" class="mt-12 editbtn" type="submit">Guardar los cambios</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>


    
    
    
    
    
    
    


    <!-- MODAL editar Contenido seccion  -->
    <v-dialog v-model="dialogEditContent" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat shrink-on-scroll color="blue-grey">
          <v-btn icon dark @click="closeModalEditContent()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>Editar contenido general de Agresiones</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items><v-btn dark text @click="closeModalEditContent()" >Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form @submit.prevent="editContent()">

            <!-- <v-text-field filled dense flat  type="text" label="Nombre de la agresión *" v-model="currentEditoContent.texto"></v-text-field> -->
            <small class="text--secondary">Primer texto <span class="text--secondary"></span></small>
            <ckeditor :editor="editor" v-model="currentEditoContent.primero" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <small class="text--secondary">Texto informativo <v-icon small>mdi-school</v-icon> </small>
            <ckeditor :editor="editor" v-model="currentEditoContent.segundo" :config="editorConfig" label="Texto de mas info"></ckeditor>
              <br>
            <v-divider></v-divider>
            <small class="text--secondary">Este no se ve, pero agrego uno extra por si se llegara a necesitar XD</small>
            <ckeditor :editor="editor" v-model="currentEditoContent.tercero" :config="editorConfig"></ckeditor>

            <v-btn block elevation="0" class="mt-12 editbtn" type="submit">Guardar los cambios</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
import {db} from '../../main'
import draggable from 'vuedraggable'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'


export default {
  name: 'AgresionList',
  data() {
    return {
      isLoading: true,
      dialog: false,
      dialogEdit: false,
      dialogEditContent: false,

      editor: ClassicEditor,
      editorConfig: {
        language: 'es',
        toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'link', 'numberedList', 'bulletedList', '|', 'insertTable', 'blockQuote', '|', 'mediaEmbed', '|', 'undo', 'redo' ],
      },

      currentPerfil: this.$route.params.category,
      currentModulo: this.$route.params.section,
      agresions: [], // Mis agresiones obtenidas desde firebase
      
      agresionAdd: {
        texto: "",
        subtexto: "",
        help: "",
        textoInforme: "",
        orden: "",
        color: '#7a77d0',
      },
      currentEdito: [],
      currentEditoContent: {},
    }

  },
  components: {
    draggable
  },
  created() {
    this.getData()
  },
  methods: {
    // OBTENGO DATOS DE Agresiones
    async getData() {
      this.isLoading = true
      try {
        const snapshot = await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).orderBy('orden').get();
        const agresions = []
        snapshot.forEach(doc => {
          let agresionData = doc.data()
          agresionData.id = doc.id
          agresions.push(agresionData)
        })
        this.agresions = agresions

        //Obtengo datos del contenido - codigo spaguetti U_U
        const doc = await db.collection('contenidos').doc(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).get()
        this.currentEditoContent = doc.data()


        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },

    // NUEVO ORDEN DE Agresiones
    async newOrder() {
      // Get a new write batch
      const batch = db.batch();
      // Prepara batch para nuevo orden 
      this.agresions.forEach((agresion, index) => {
        const Ref = db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(agresion.id);
        batch.update(Ref, {orden: index});
      })
      try {
        // Commit the batch
        await batch.commit();
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },

    // GUARDO DATOS DE agresión
    async addAgresion() {
      try {
        if(this.agresionAdd.texto && this.agresionAdd.help) {
          this.agresionAdd.orden = this.agresions.length
          await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).add(this.agresionAdd).then(() => {
            this.getData()
            this.closeModal()
          })
        } else {
          alert('Algunos campos obligatorios faltan obligatorios')
        }
      } catch (error) {
        console.log(error)
      }

    },
    closeModal() {
      this.agresionAdd = {
        texto: "",
        subtexto: "",
        help: "",
        textoInforme: "",
        orden: "",
        color: '#7a77d0',
      }
      this.dialog = false
    },

    // EDITO LA Agresion
    async editAgresion() {
      const docuid = this.currentEdito.id
      delete this.currentEdito.id
      try {
        await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(docuid).update(this.currentEdito)
        this.closeModalEdit()
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },
    modalEdit(datos) {
      this.currentEdito = JSON.parse(JSON.stringify(datos))
      this.dialogEdit = true
    },
    closeModalEdit() {
      this.currentEdito = []
      this.currentEdito.color = '#7a77d0'
      this.dialogEdit = false
    },

    // ELIMINO DE AGRESION
    async deleteAgresion(agresionID) {
      let proceed = confirm("Estas segura(o) que quieres eliminar esta agresión ID: " + agresionID);
      if (proceed) {
        await db.collection(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).doc(agresionID).delete().then(() => {
        }).catch((error) => {
          console.error("Error removing document: ", error);
        });
        this.getData()
      } else {
        console.log('La agresión no se elimino ID: ' + agresionID)
      }
    },








    // EDITO Contenido seccion
    async editContent() {
      const docuid = this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()
      


      // console.log(this.agresions)
      // delete this.currentEditoContent.id
      try {
        await db.collection('contenidos').doc(docuid).update(this.currentEditoContent)
        this.closeModalEditContent()
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },
    // modalEditContent(datos) {
    //   this.currentEditoContent = JSON.parse(JSON.stringify(datos))
    //   this.dialogEditContent = true
    // },
    async closeModalEditContent() {
      try {
        const doc = await db.collection('contenidos').doc(this.currentPerfil.toLowerCase() + '_' + this.currentModulo.toLowerCase()).get()
        this.currentEditoContent = doc.data()
        this.dialogEditContent = false
      } catch (error) {
        console.log(error)
      }
    },





    ...mapMutations(['currentChange'])
  },
  computed: {
    ...mapState(['usuario', 'currentComponente'])
  },
  
}
</script>

<style scoped>
.card-agresion {
  border-style: solid;
  border-width: 1.5ex 0.5ex 0.5ex !important;
  /* border-width: 0.5ex 0.5ex 1.5ex !important; */
}
</style>